<script setup lang="ts">
import { useServiceStore } from '~~/store/service'

withDefaults(defineProps<{
  size?: 'small' | 'middle'
  type?: 'off-hours' | 'unavailable'
}>(), {
  size: 'middle',
  type: 'off-hours',
})
const dateStore = useServiceStore()
</script>

<template>
  <div :class="`unavailable-factoid_${size}`">
    <div
      class="unavailable-factoid"
      :class="`unavailable-factoid_${type}`"
    >
      <div class="unavailable-factoid__title">
        Режим «Просмотра»
      </div>
      <div v-if="type === 'unavailable'" class="unavailable-factoid__description">
        <p>Временно</p>
        не принимаем заказы
      </div>
      <div v-else class="unavailable-factoid__description">
        Доставка будет<br v-if="size === 'middle'">
        возможна с {{ dateStore.schedule.from }}<br>
        Сейчас мы не работаем
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.unavailable-factoid {
  height: 100%;
  padding: 20px;
  color: #fff;
  border-radius: 20px;

  &__title {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 500;
    line-height: 24px;
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  &_off-hours {
    background-image: url('./asset/moon.png'), linear-gradient(90deg, #626262 0%, #202020 100%);
    background-repeat: no-repeat;
    background-position: right -40px bottom -66px, 0;
    background-size: 275px 260px, 100%;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url('./asset/moon@2x.png'), linear-gradient(90deg, #626262 0%, #202020 100%);
    }
  }

  &_unavailable {
    display: flex;
    flex-direction: column;
    background-image: url('./asset/close.png'), linear-gradient(90deg, #626262 0%, #202020 100%);
    background-repeat: no-repeat;
    background-position: right 10px bottom -10px, 0;
    background-size: 155px 155px, 100%;

    ^&__description {
      margin-top: auto;
    }
  }

  &_middle {
    width: 100%;
    height: 137px;

    @media (max-width: 1000px) {
      height: 90px;

      ^&__title {
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 17px;
      }

      ^&__description {
        font-size: 12px;
        line-height: 14px;
      }

      & .unavailable-factoid_unavailable {
        padding: 12px;
        background-size: 110px 110px,100%;
        border-radius: 10px;
      }

      & .unavailable-factoid_off-hours {
        padding: 12px;
        background-position: right -12px bottom -21px,0;
        background-size: 155px 140px,100%;
      }
    }
  }

  &_small {
    min-height: 90px;
    border-radius: 20px;

    & .unavailable-factoid_off-hours {
      padding: 15px 10px;
      background-position: right -30px bottom -45px, 0;
      background-size: 192px 173px, 100%;

      ^^&__title {
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 17px;
      }

      @media (max-width: 1300px) {
        background-size: 0 0, 100%;
      }
    }

    & .unavailable-factoid_unavailable {
      padding: 14px 12px;
      background-position: right 10px bottom -10px, 0;
      background-size: 110px 110px, 100%;
      border-radius: 10px;

      ^^&__description {
        font-size: 13px;
        line-height: 17px;
      }
      @media (max-width: 1100px) {
        background-size: 0 0, 100%;
      }
    }

    &-unavailable {
      display: flex;
      flex-direction: column;
      height: auto;
      background-size: 105px 105px,100%;
      border-radius: 10px;
    }
  }
}
</style>
